<template>
  <div class="lsbox">
        <div class="base_w">
          <div class="common_title_box">
            <span class="title">金牌律师</span>
            <div class="righttext" @click.stop="tourl('/lvlist')">
                更多
                <img src="@/assets/img/right.png" alt="" class="iconimg">
            </div>
          </div>
          <div class="ls flexbox">
            <div class="ls_item" v-for="(item,index) in Lawlist" :key="index" @click.stop="tourl('/lvdetails?id=' + item.id)">
              <a href="javascript:;" class="ls_item">
              <img :src="item.photo" alt="" class="lsimg">
              <div class="lstopimg">
                <span>执业{{item.workYears}}年</span>
              </div>
              <div class="ls_text">
                <p class="title">{{item.lawyerName}}律师</p>
                <div class="text">
                  {{item.goodsIds}} 
                </div>
                <div class="btnbox" @click.stop="tourl('/wenls?lawid=' + item.id)">向他咨询</div>
              </div>
            </a>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import apiUrl from '@/api/main'
export default {
  name: '',
  components: {},
  props: {
    // type: {
    //   type: Number,
    //   default: 1
    // }
  },
  data() {
    return {
      Lawlist:[]
    }
  },

  mounted() {
    this.getlsLawyerindex();
  },

  methods: {
    // 金牌律师列表
    getlsLawyerindex() {
      let data = {
        pageSize: 6,
        pageNum: 1,
        tuijian: 1,
        orderByColumn: "haopinhlv",
        isAsc: "desc",
      }
      apiUrl.lsLawyerindex(data).then((res) => {
          this.Lawlist = res.rows;
      })
    },
     // 跳转
     tourl(url) {
      this.$router.push({
        path: url
      })
    }
    
  }
}
</script>

<style lang="scss">
.lsbox{
  height: 488px;
  background-color: #fff;
  padding: 32px 0 48px;
  .ls{
    margin-top: 21px;
    .ls_item{
      width:230px;
      height: 350px;
      background: #EBF0F9;
      border-radius: 24px;
      position: relative;
      margin-right: 12px;
      .lsimg{
        width:230px;
        height: 230px; // 142px 192px
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        object-fit: cover;
        object-position: left 0px top 0px;
      }
      .lstopimg{
        position: absolute;
        top: 60%;
        left: 60px;
        width: 128px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: url('../assets/img/lstop.png') no-repeat;
        background-size: 100% 100%;
        span{
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
      .ls_text{
        height: 100px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title{
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin: 16px 0 6px;
        }
        .text{
          width: 160px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #777777;
          line-height: 18px;
          text-align: center;
        }
        .btnbox{
          display: none;
          width: 120px;
          height: 36px;
          line-height: 36px;
          background: linear-gradient(270deg, #4298FF 0%, #305BFE 100%);
          border-radius: 24px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          margin-top: 20px;
        }
      }
    }
    .ls_item:hover{
        .lsimg{
          height: 170px;
        }
        .lstopimg{
          top: 43%;
        }
        .ls_text{
          height: 147px;
        }
        .btnbox{
          display: block;
        }
      }
    .ls_item:nth-child(6){
        margin-right: 0px !important;
      }
  }
}

</style>