<template>
  <div class="findindex">
    <div class="topbg">
      <p class="topbgti">到勤问律师平台，找专业靠谱律师</p>
      <div class="soubox">
        <el-input
          placeholder="请输入律师姓名"
          v-model="searchVal"
          class="input-with-select"
        >
          <div class="flex_between" slot="append" @click.stop="tosearch('/lvlist?searchname=' + searchVal)">
            <img src="@/assets/img/search.png" alt="" class="searchimg" />
          </div>
        </el-input>
      </div>
    </div>
    <div class="iconbox base_w">
      <div class="qxxwslx3 flexbox">
        <div>
          <img src="@/assets/img/fw1.png" alt="" />
          服务保障
        </div>
        <div>
          <img src="@/assets/img/fw2.png" alt="" />
          认证律师
        </div>
        <div>
          <img src="@/assets/img/fw3.png" alt="" />
          急速响应
        </div>
        <div>三重认证为您甄选靠谱律师，请放心选择！</div>
      </div>
    </div>
    <jinpails></jinpails>
    <!-- 找律师指南 -->
    <div class="zlsbox base_w flex">
      <div class="leftleft">
        <div class="common_title_box">
          <span class="title">找律师指南</span>
        </div>
        <div class="zlsboxleft">
          <div class="leftbox">
            <p class="lefttile"><span>|</span>什么情况下需要找律师？</p>
            <div>
              您是否为
              <span>朋友欠钱不还，无故被辞退，合同遭违约 </span>
              等问题烦恼过？当您的合法权益受到潜侵害时，第一时间找律师
              <span>提供咨询服务 </span>
              ，能及时避免损失，防止承担不必要的法律风险。
            </div>
          </div>
          <div class="leftbox">
            <p class="lefttile"><span>|</span>找律师的好处是？</p>
            <div>
              律师具备专业的法律知识及丰富的办案经验，可为您全面分析案情，发现潜在风险，高效解决法律问题。
            </div>
          </div>
        </div>
      </div>

      <div class="rightright">
        <div class="common_title_box">
          <span class="title">如何挑选律师？</span>
        </div>
        <div class="zlsboxright flex">
          <div class="zlsboxrightmain mr_20">
            <p class="rightp flexbox">
              <span></span>
              <span>法律咨询</span>
              <span>建议参考以下3个方面挑选律师</span>
            </p>
            <div class="tuwen flex_between">
              <div class="tuwenitem flex_column_center">
                <img src="@/assets/img/z1.png" alt="" />
                <p>专业相符</p>
                <div>在擅长的领域考虑更全面</div>
              </div>
              <div class="tuwenitem flex_column_center">
                <img src="@/assets/img/z2.png" alt="" />
                <p>律师业绩</p>
                <div>回复数量可以体验积极性</div>
              </div>
              <div class="tuwenitem flex_column_center">
                <img src="@/assets/img/z3.png" alt="" />
                <p>好评率</p>
                <div>展示律师沟通技巧</div>
              </div>
            </div>
          </div>
          <div class="zlsboxrightmain">
            <p class="rightp flexbox">
              <span></span>
              <span>打官司</span>
              <span>建议参考以下3个方面挑选律师</span>
            </p>
            <div class="tuwen flex_between">
              <div class="tuwenitem flex_column_center">
                <img src="@/assets/img/z1.png" alt="" />
                <p>法院人脉</p>
                <div>看对当地法院熟悉程度</div>
              </div>
              <div class="tuwenitem flex_column_center">
                <img src="@/assets/img/z2.png" alt="" />
                <p>专业相符</p>
                <div>在擅长领域胜诉率更高哦</div>
              </div>
              <div class="tuwenitem flex_column_center">
                <img src="@/assets/img/z3.png" alt="" />
                <p>案件数量</p>
                <div>案件量是经验的证明</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 按类型/地区找律师 -->
    <div class="findlsbox base_w flex">
      <div class="common_index_leftwidth">
        <div class="common_title_box">
          <span class="title">按类型/地区找律师</span>
        </div>
        <div class="typebox flexbox">
          <span  @click.stop="typeFun(-1)" :class="[navactive == -1 ? 'active' : '']">全部类型</span>
          <span v-for="(item,i) in LawKnowledgelist" :key="i" @click.stop="typeFun(i,item)" :class="[navactive == i ? 'active' : '']">{{item.name}}</span>
        </div>
        <div class="subtext">
          <div class="text1 flexbox">
            <span> 按地区查找</span>  
            <el-cascader
              v-model="Dataval"
              :options="CityInfo"
              :props="TagProps"
              placeholder="全国"
              @change="handleChange"
            >
            </el-cascader>
		      </div>
        </div>
        <div class="lvsbox flexbox">
          <div class="lvsitem flexbox" v-for="(item,i) in Lawlist" :key="i" @click.stop="tourl('/lvdetails?id=' + item.id)">
            <img :src="item.photo" alt="" class="lsimg" />
            <div class="lsright">
              <p class="text1">{{item.lawyerName}}律师</p>
              <div class="text2">
                好评率：<span>{{ item.haopinhlv }}%</span>接单量: <span>{{item.jiedanums}}次</span>
              </div>
              <p class="text3 flexbox">
                <span v-for="(data, index) in item.goodsIdsArr" :key="index">{{data}}</span>
              </p>
              <p class="text4">{{item.addr}}</p>
            </div>
          </div>
          <div class="more" @click.stop="tourl('/lvlist?typename=' +typename + '&chooseCityText=' + chooseCityText + '&provinceVal=' + provinceVal + '&cityVal=' + cityVal )" v-if="Lawlist.length > 0">
            更多 <img src="@/assets/img/right.png" alt="" />
          </div>
        </div>
        <NoData v-if="Lawlist.length <= 0"></NoData>
      </div>
      <div class="common_index_rightwidth">
        <guanggao :type="1"></guanggao>
        <!-- 律师最新回复 -->
        <div class="lshfbox">
          <div class="common_title_box">
            <span class="title">律师最新回复</span>
          </div>
          <div class="lshfmainbox">
            <div class="mainitem" v-for="(item,i) in wentypedalist" :key="i">
              <div class="itemlsbox flex" v-if="item.lsLawyer">
                <div class="itemlsboxleft flex" @click.stop="tourl('lvdetails?id=' + item.lsLawyer.id)" >
                  <img :src="item.lsLawyer.photo" alt="" class="lsimg" />
                  <div class="lsinfo flex_column_between">
                    <p class="text1">{{item.lsLawyer.lawyerName}}律师</p>
                    <div class="text2">
                      好评率：<span class="mr15">{{item.lsLawyer.haopinhlv}}%</span> 接单量: <span>{{item.lsLawyer.jiedanums}}次</span>
                    </div>
                  </div>
                </div>
                <!-- <p class="itemlsboxright" v-if="item.lsFuwuOrderMsgs[0].createTime">{{ item.lsFuwuOrderMsgs[0].createTime | fifterTime}}</p> -->
              </div>

              <div class="zixuntop flex_topstart" @click.stop="tourl('/wendetails?id=' + item.id)">
                <img src="@/assets/img/wen.png" alt="" />
                <span class="">{{item.content}}</span>
              </div>
              <!-- 律师答 -->
              <div class="dabox" v-if="item.lsFuwuOrderMsgs.length > 0">
                <div class="daitem">
                  <div class="topdaitem flex_topstart">
                    <img src="@/assets/img/da.png" alt="" />
                    <span class="" v-if="item.lsFuwuOrderMsgs.length > 0"> {{item.lsFuwuOrderMsgs[0].content}}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="btnbox" @click.stop="tourl('/toask')">去提问</div>
          </div>
          <!-- ============ -->
        </div>

        <!-- 法律资讯 -->
        <div class="flzxbox">
          <div class="common_title_box">
            <span class="title">法律资讯</span>
          </div>
          <div class="newsbox">
            <news :type="2" :list="informationlist"></news>
          </div>  
          <guanggao :type="2"></guanggao>
        </div>
      </div>
    </div>

    <!-- 热门法律标签 -->
    <div class="hotlabel base_w">
      <div class="common_title_box">
        <span class="title">热门法律标签</span>
      </div>
      <div class="labelbox flexbox">
        <span v-for="(item,i) in LawKnowledgelist" :key="i" @click.stop="tourl('/wenfa?title=' + item.name)">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import resetTime from "@/util/check.js";
import Dataoptions from '@/util/Data'
import jinpails from '@/components/jinpails'
import guanggao from '@/components/guanggao'
import news from '@/components/news'
import NoData  from '@/components/NoData'
import { 
	provinceAndCityDataPlus,
	CodeToText
} from 'element-china-area-data'

import apiUrl from '@/api/main'
export default {
  components: { jinpails, guanggao,news,NoData },
  data() {
    return {
      options: provinceAndCityDataPlus,
      Lawlist:[], // 律师列表
      informationlist: [], //法律资讯
      navactive:-1, // 类型标签
      chooseCityText:'', // 地区
      searchVal:'', // 律师姓名
      pageSize:10, // 分页

      // 地区
      Dataval: [],
      CityInfo: Dataoptions,
      provinceVal:'', // 省
      cityVal:'', // 市
      // 更改组件默认的数据键
      TagProps: {
        value: 'label',
        label: 'label',
        children: 'children'
      },
      LawKnowledgelist:[], // 律师擅长标签
      typename:'', // 分类名称

      wentypedalist:[], // 问答列表
    }
  },
  filters: {
			fifterTime(value) {
				let time = value ? value.replace(/-/g, '/') : '';
				if (time) {
					return resetTime.timeago(new Date(time).getTime());
				}
			}
		},
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.lsLawyerlist(); // 获取默认律师列表
    this.getinformationlist(); // 法律资讯
    this.getlsLawKnowledgelist(); // 律师擅长标签
    this.gettypewendalist(); // 问答列表
    // 跳转回顶部
    document.documentElement.scrollTop = 0;
  },
  methods: {
	  // 省市区三级联动 按地区查找律师
	  handleChange(value){
      console.log(value)
      if(value[0] == '全国'){
        this.chooseCityText = ''
      } else {
        this.provinceVal = value[0];
        this.cityVal = value[1];
        this.chooseCityText = value[0]+value[1];
      }
      this.lsLawyerlist();
	  },
    // 跳转
    tourl(url) {
      this.$router.push({
        path: url
      })
    },
    tosearch(url){
      if(!this.searchVal){
        this.$message({
          message:'请输入律师姓名',
          type:'error'
        });
        return;
      }

      this.$router.push({
        path: url
      })
    },
    // 点击类型标签 按类型查找律师
    typeFun(index,item){
  
       // 全部类型
       if(index == -1){
          this.navactive = -1;
          this.typename = ''
        } else {
          this.navactive = index;
          this.typename = item.name;
        }
        this.lsLawyerlist();
    },
  
    // 获取律师列表
    lsLawyerlist(){
      let data = { 
        pageNum: 1,
        pageSize: 12,
        // orderByColumn: " ",
        // isAsc: "desc",
        addr: this.chooseCityText, // 地区
        // lawyerName: this.searchVal,  // 律师姓名
        goodsIds: this.typename
      }
      apiUrl.lsLawyerindex(data).then((res) => {
          this.Lawlist = res.rows;

          res.rows.forEach(element => {
            if(element.goodsIds){
              element.goodsIdsArr = element.goodsIds.split(',')
            }
          });
      })
    },
    // 获取法律资讯 
    getinformationlist() {
      let data = {
        userId:localStorage.getItem('userId'),
        pageSize: 3,
				pageNum: 1,
      }
      apiUrl.lsInformationlist(data).then((res) => {
          this.informationlist = res.rows;
      })
    },

    // 律师擅长标签
    getlsLawKnowledgelist() {
      let data = {
				pId: 0,
        userId: localStorage.getItem('userId')
      }
      apiUrl.lsLawKnowledgelist(data).then((res) => {
          this.LawKnowledgelist = res.rows;
      })
    },

      // 获取问答列表
      gettypewendalist() {
        let data = {
          pageSize: 2,
          pageNum: 1,
        }
        apiUrl.wendalist(data).then((res) => {
            this.wentypedalist = res.rows;
        });
      },
  },
  
}
</script>
<style lang='scss'>
	.el-cascader__dropdown{
		height: 300px;
		overflow-y: scroll;
	}
.findindex {
  .topbg {
    background: url('../../assets/img/bg13.png') no-repeat;
    background-size: 100% 100%;
    height: 320px;
    margin: 0 auto;
    text-align: center;
    .topbgti {
      font-size: 40px;
      font-weight: 600;
      color: #ffffff;
      letter-spacing: 1px;
      padding-top: 64px;
    }
    .soubox {
      width: 560px;
      height: 52px;
      line-height: 52px;
      background: #ffffff;
      border-radius: 26px;
      margin: 0 auto;
      margin-top: 42px;
      .el-input-group {
        border-radius: 10px;
        border: 1px solid transparent;
      }
      .el-select .el-input {
        width: 467px;
      }
      .el-input-group > .el-input__inner {
        height: 43px;
      }
      .el-input__inner {
        border-top-left-radius: 22px;
        border-bottom-left-radius: 22px;
        border: 0px solid #fff;
      }
      .input-with-select .el-input-group__prepend {
        background-color: #fff;
      }
      .el-input-group__append {
        border-top-right-radius: 22px;
        border-bottom-right-radius: 22px;
        background-color: #fff;
        border: 0px solid #fff;
      }
      .searchimg {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
  .iconbox {
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 7px 20px 0px rgba(34, 42, 53, 0.08);
    border-radius: 10px;
    transform: translateY(-37px);

    .qxxwslx3 {
      padding: 29px 64px;
      div {
        margin-right: 33px;
        display: flex;
        align-items: center;
        font-size: 16px;
      }
      div:nth-last-child(1) {
        margin-left: 20px;
      }
      img {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
    }
  }

  // 找律师指南
  .zlsbox {
    padding-bottom: 40px;
    border-bottom: 3px solid #333333;
    .leftleft {
      width: 464px;
      margin-right: 20px;
    }
    .rightright {
      width: 976px;
    }
    .common_title_box {
      margin-bottom: 20px;
    }
    .zlsboxleft {
      width: 464px;
      height: 334px;
      background: url('../../assets/img/bg14.png') no-repeat;
      background-size: 100% 100%;
      padding: 40px 40px 40px 20px;
      .leftbox {
        margin-bottom: 27px;
        .lefttile {
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
          span {
            width: 3px;
            height: 13px;
            background: #ffffff;
            border-radius: 1px;
            margin-right: 13px;
          }
        }
        div {
          font-size: 16px;
          font-weight: 400;
          color: #a8a8a8;
          line-height: 25px;
          margin-top: 13px;
          padding-left: 16px;
          span {
            color: #ffcd00;
          }
        }
      }
    }
    .zlsboxright {
      width: 976px;
      height: 334px;
      .mr_20 {
        margin-right: 20px;
      }
      .zlsboxrightmain {
        width: 464px;
        height: 334px;
        background: #f6f6f6;
        border-radius: 10px;
        padding: 32px;
        .rightp {
          span:nth-child(1) {
            width: 6px;
            height: 18px;
            background: #305bfe;
            border-radius: 2px;
            margin-right: 12px;
          }
          span:nth-child(2) {
            font-size: 20px;
            font-weight: 600;
            color: #555555;
            margin-right: 20px;
          }
          span:nth-child(3) {
            font-size: 16px;
            font-weight: 500;
            color: #a8a8a8;
          }
        }
        .tuwen {
          margin-top: 38px;
          .tuwenitem {
            width: 110px;
            img {
              width: 80px;
              height: 80px;
            }
            p {
              font-size: 16px;
              font-weight: 600;
              color: #333333;
              margin-bottom: 10px;
            }
            div {
              font-size: 16px;
              color: #777777;
              text-align: center;
            }
          }
        }
      }
    }
  }

  // 按类型/地区找律师
  .findlsbox {
    padding-top: 33px;
    padding-bottom: 40px;
    border-bottom: 3px solid #333333;
    .common_index_leftwidth {
      .typebox {
        flex-wrap: wrap;
        margin-top: 33px;
        p {
          background: #305bfe;
          border-radius: 21px;
          padding: 7px 16px;
          font-size: 18px;
          color: #ffffff;
          margin-right: 12px;
          margin-bottom: 24px;
        }
        span {
          padding: 6px 13px;
          font-size: 18px;
          border: 1px solid #cccccc;
          color: #333333;
          cursor: pointer;
          border-radius: 18px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
        span:hover {
          color: #305bfe;
          border: 1px solid #305bfe;
        }
        span.active {
          background: #305bfe;
          color: #ffffff;
        }
      }
      .subtext {
        margin-top: 21px;
        margin-bottom: 28px;
        .text1 {
          font-size: 20px;
          font-weight: 500;
          color: #333333;
          span{
            width: 100px;
            margin-right: 13px;
          }
        }
        .el-cascader{
          width:200px !important;
          border: 1px solid #ccc;
        }
        .el-cascader .el-input{
          width:198px !important;
        }
      }
      .lvsbox {
        flex-wrap: wrap;
        .lvsitem {
          width: 403px;
          // height: 163px;
          background: #ffffff;
          border-radius: 12px;
          border: 1px solid #e6e6e6;
          margin-right: 24px;
          margin-bottom: 24px;
          padding: 16px 15px 16px 16px;
          .lsimg {
            width: 124px;
            height: 164px;
            border-radius: 12px;
          }
          .lsright {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            min-height: 164px;
            margin-left: 16px;
            .text1 {
              font-size: 20px;
              font-weight: 500;
              color: #333333;
            }
            .text2 {
              font-size: 16px;
              color: #333333;
              span {
                color: #305bfe;
                margin-right: 16px;
              }
            }
            .text3 {
              flex-wrap: wrap;
              span {
                padding: 4px 10px;
                border-radius: 14px;
                border: 1px solid #cccccc;
                font-size: 13px;
                color: #333333;
                margin-right: 4px;
                margin-bottom: 5px;
              }
              span:nth-child(3n) {
                margin-right: 0px;
              }
            }
            .text4 {
              font-size: 16px;
              color: #666666;
            }
          }
        }
        .lvsitem:hover{
          cursor: pointer;
          border: 1px solid #305bfe;
        }
        .lvsitem:nth-child(2n) {
          margin-right: 0px;
        }
        .more {
          font-size: 16px;
          color: #777777;
          margin-right: 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    .common_index_rightwidth {
      .lshfbox {
        padding-top: 24px;
        .common_title_box {
          padding-bottom: 16px;
        }
      }
      // =========
      .lshfmainbox {
        width: 586px;
        background: #f8f8f8;
        border-radius: 12px;
        padding: 0 32px 32px 32px;
        .mainitem{
          padding-top: 32px;
          padding-bottom: 32px;
          border-bottom: 1px solid #E6E6E6;
        }
        .itemlsbox {
          justify-content: space-between;

          .itemlsboxleft {
            cursor: pointer;
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 13px;
            }
            .lsinfo {
              height: 40px;
              .text1 {
                font-size: 18px;
                font-weight: 500;
                color: #333333;
              }
              .text2 {
                font-size: 16px;
                color: #333333;
                span {
                  color: #305bfe;
                }
              }
            }
          }
          .itemlsboxright {
            font-size: 12px;
            color: #777777;
          }
        }

        .zixuntop {
          margin-top: 20px;
          cursor: pointer;
          img {
            width: 22px;
            height: 22px;
            margin-right: 12px;
          }
          span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            word-break: break-all;
          }
        }
        .dabox {
          margin-top: 17px;
          width: 423px;
          .daitem {
            background: #f5f5f5;
            border-radius: 0px 10px 10px 10px;
            background: #ececec;
            .topdaitem {
              padding: 13px;
              img {
                width: 18px;
                height: 18px;
                margin-right: 10px;
              }
              span {
                display: inline-block;
                font-size: 16px;
                color: #333333;
                line-height: 20px;
              }
            }
          }
        }

        .btnbox{
          width: 184px;
          height: 48px;
          line-height: 48px;
          border-radius: 36px;
          margin: 25px auto 0;
          font-size: 18px;
        }
      }
      // =======

      // 法律资讯
      .flzxbox{
        margin-top: 24px;
        .common_title_box{
          margin-bottom: 16px;
        }
        .newsbox{
          width: 586px;
          background: #F8F8F8;
          border-radius: 12px;
          padding: 32px;
        }
      }
    }
  }

  // 热门法律标签
  .hotlabel{
    padding-top: 33px;
    padding-bottom: 20px;
    .common_title_box{
      margin-bottom: 33px;
    }
    .labelbox{
      flex-wrap: wrap;
      span{
          border-radius: 18px;
          border: 1px solid #CCCCCC;
          padding: 9px 32px;
          font-size: 16px;
          color: #333333;
          cursor: pointer;
          margin-right: 16px;
          margin-bottom: 24px;
      }
      span:hover{
        color: #305bfe;
        border: 1px solid #305bfe;
      }
    }
  }
  .mr15{
    margin-right: 15px;
  }
}
</style>